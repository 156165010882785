import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ge/mit/geyang.github.io/src/components/mdx-layout.js";
import { StaticImage } from "gatsby-plugin-image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 style={{
      padding: "20px 0 0 0"
    }}>Ge Yang</h1>
    <div style={{
      minHeight: 300,
      marginBottom: 40,
      textAlign: "justify"
    }}>
      <img src="/29552528604ada2f053048ce182c0eb7/Ge_profile.jpg" style={{
        float: "right",
        marginLeft: 30,
        marginBottom: 20
      }} width={300} />
I am a postdoctoral researcher working with Phillip Isola at MIT CSAIL and The Institute for AI and Fundamental Interactions (IAIFI). My research focuses on developing the algorithmic and system foundations for computational visuomotor learning, with an emphasis on learning from synthetic data and sim-to-real.
      <p>{`My research spans a diverse set of techniques and challenges, but centers around three fundamental goals: maximizing robot performance and robustness, making robots versatile, and developing the intelligence necessary for general embodied problem-solving. In essence, my work is dedicated to building robots that are capable, versatile, and intelligent.`}</p>
      <p>{`Before transitioning into AI and robotics, I earned my Ph.D. in Physics from the University of Chicago, where working with David I. Schuster planted the seed for my multidisciplinary approach to problem-solving. My academic journey began with an undergraduate degree in Mathematics and Physics from Yale. I am honored to be a recipient of the NSF Institute of AI and Fundamental Interactions Postdoc Fellowship, and the Best Paper Award at The Conference of Robot Learning (CoRL) in 2024 (one out of 499 submissions).`}</p>
      <hr />
    </div>
    <h1>{`Recent Projects`}</h1>
    <h1>{`Open-source Tools`}</h1>
    <p>{`The best way to affect change, is to build tools. Here are a few out of 40+ opensource packages
I  have published over the years.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`jaynes`}</strong>{` - `}<a parentName="li" {...{
          "href": "https://pypi.org/project/jaynes/"
        }}>{`v0.5.25`}</a>{` - Enable large scale ML training across compute providers
`}<a parentName="li" {...{
          "href": "https://github.com/geyang/jaynes-starter-kit"
        }}>{`[link]`}</a></li>
      <li parentName="ul"><strong parentName="li">{`ml-logger`}</strong>{` - `}<a parentName="li" {...{
          "href": "https://pypi.org/project/ml-logger/"
        }}>{`0.4.46`}</a>{` - A distributed logging and visualizer for ML research
`}<a parentName="li" {...{
          "href": "https://github.com/geyang/ml_logger"
        }}>{`[link]`}</a></li>
      <li parentName="ul"><strong parentName="li">{`params-proto`}</strong>{` -
`}<a parentName="li" {...{
          "href": "https://pypi.org/project/params-proto/"
        }}>{`v2.6.0`}</a>{` - singleton design pattern for defining ML model parameters
`}<a parentName="li" {...{
          "href": "https://github.com/geyang/params_proto"
        }}>{`[link]`}</a></li>
      <li parentName="ul"><strong parentName="li">{`CommonMark X (CMX)`}</strong>{` -
`}<a parentName="li" {...{
          "href": "https://pypi.org/project/cmx/"
        }}>{`v2.6.0`}</a>{` - Modern replacement of Jupyter notebooks, python to markdown.
`}<a parentName="li" {...{
          "href": "https://github.com/geyang/cmx-python"
        }}>{`[link]`}</a></li>
      <li parentName="ul"><strong parentName="li">{`luna`}</strong>{` -
`}<a parentName="li" {...{
          "href": "https://www.npmjs.com/package/luna"
        }}>{`v1.6.3`}</a>{` - a rxjs implementation of redux. Implemented in typescript.
`}<a parentName="li" {...{
          "href": "https://github.com/escherpad/luna"
        }}>{`[link]`}</a></li>
      <li parentName="ul"><strong parentName="li">{`luna-saga`}</strong>{` -
`}<a parentName="li" {...{
          "href": "https://www.npmjs.com/package/luna"
        }}>{`v6.2.1`}</a>{` - a co-routine runner for Luna. Enables generator-based async flow
`}<a parentName="li" {...{
          "href": "https://github.com/escherpad/luna-saga"
        }}>{`[link]`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      